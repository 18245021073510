import { requestNative } from 'js/lib/api/api-request'
import { notifyBugsnag } from 'js/lib/bugsnag'

const MEDIA_CLASS = '.js-c-media--manager'
const ITEM_CLASS = '.js-c-media--manager__item'
const NOTICE_CLASS = '.js-c-media--manager__notice'
const DELETE_BUTTON_CLASS = '.js-c-media--manager__delete'
const DRAGGED_ITEM_OPACITY = '0.5'
const DEFAULT_OPACITY = '1'
const HIGHLIGHT_CLASSES = 'tw-outline-dashed tw-outline-blue-500 tw-outline-2 outline-offset-2'

// insert an element after a reference element
const insertAfter = (newNode, referenceNode) => {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
}

// get the closest item element
const getItem = (event) => event.target.closest(ITEM_CLASS)

// toggle border style
const toggleBorder = (element, add) => {
  if (element) {
    if (add) {
      element.classList.add(...HIGHLIGHT_CLASSES.split(' ')) 
    } else {
      element.classList.remove(...HIGHLIGHT_CLASSES.split(' ')) 
    }
  }
}

// handle item dragging
const onDragStart = (event) => {
  const item = getItem(event)
  if (item) {
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('text/plain', null) // For Firefox
    item.style.opacity = DRAGGED_ITEM_OPACITY
    item.dataset.dragged = 'true'
  }
}

const onDragEnd = (event) => {
  const item = event.target.closest(ITEM_CLASS)
  if (item) {
    item.style.opacity = DEFAULT_OPACITY
    delete item.dataset.dragged
    toggleBorder(item, false)
  }
}

// handle visual feedback during drag
const onDragOver = (event) => {
  event.preventDefault()
  if (getItem(event)) {
    toggleBorder(getItem(event), true)
  }
}

const onDragLeave = (event) => {
  const target = event.target.closest(ITEM_CLASS)
  if (target) {
    toggleBorder(target, false)
  }
}

// handle drop event and update media order
const onDrop = (event, media) => {
  event.preventDefault()
  const target = event.target.closest(ITEM_CLASS)
  const draggedItem = document.querySelector(`[data-dragged="true"]`)

  if (target && draggedItem && draggedItem !== target) {
    const shouldInsertAfter = Array.prototype.indexOf.call(target.parentNode.children, draggedItem) < Array.prototype.indexOf.call(target.parentNode.children, target)
    if (shouldInsertAfter) {
      insertAfter(draggedItem, target)
    } else {
      target.parentNode.insertBefore(draggedItem, target)
    }
    updateOrder(media, draggedItem)
  }
  toggleBorder(target, false)
  if (draggedItem) {
    toggleBorder(draggedItem, false)
  }
}

// Update the media order by setting the `data-order` attribute
const updateOrder = (media, draggedItem) => {
  const items = media.querySelectorAll(ITEM_CLASS)
  items.forEach((item, index) => {
    item.dataset.order = index + 1
    item.setAttribute('aria-label', `Item ${index + 1}`)
  })
  sendOrderUpdate(media, draggedItem)
}

// Send the updated order
async function sendOrderUpdate(media, draggedItem) {
  const items = media.querySelectorAll(ITEM_CLASS);
  const postToUrl = media.dataset.updateMedia;
  const formAction = 'reorder'

  const mediaOrder = Array.from(items).map(item => ({
    id: item.getAttribute('data-id'),
    order: item.getAttribute('data-order')
  }))

  try {
    const response = await requestNative({
      url: postToUrl,
      method: 'POST',
      data: { form_action: formAction, media_order: mediaOrder }
    })

    if (response.message) {
      const notice = draggedItem.querySelector(NOTICE_CLASS)
      notice.classList.remove('tw-hidden')

      setTimeout(() => {
        notice.classList.add('tw-hidden');
      }, 3000)
    }
    
  } catch (error) {
    notifyBugsnag(error)
  }
}

// handle keyboard navigation
const onKeyDown = (event) => {
  const currentItem = getItem(event)
  if (currentItem) {
    const media = event.target.closest(MEDIA_CLASS)
    const allItems = Array.from(media.querySelectorAll(ITEM_CLASS))

    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowLeft':
        moveItem(currentItem, allItems, -1)
        break
      case 'ArrowDown':
      case 'ArrowRight':
        moveItem(currentItem, allItems, 1)
        break
      case 'Enter':
      case ' ':
        currentItem.focus()
        break
    }
  }
}

// move items with keyboard
const moveItem = (item, allItems, direction) => {
  const currentIndex = allItems.indexOf(item)
  const targetIndex = currentIndex + direction

  if (targetIndex >= 0 && targetIndex < allItems.length) {
    const targetItem = allItems[targetIndex]
    if (direction < 0) {
      item.parentNode.insertBefore(item, targetItem)
    } else {
      insertAfter(item, targetItem)
    }
    item.focus()
    updateOrder(item.closest(MEDIA_CLASS), item)
  }
}

const handleDelete = async (event) => {
  event.preventDefault()
  
  const button = event.target.closest(DELETE_BUTTON_CLASS)

  if (button) {
    const item = button.closest(ITEM_CLASS)

    if (item) {
      const media = item.closest(MEDIA_CLASS)
      const itemId = item.getAttribute('data-id')
      const postToUrl = media.dataset.updateMedia

      const isConfirmed = window.confirm("Are you sure you want to delete this item?")

      if (isConfirmed) {
        try {
          const response = await requestNative({
            url: postToUrl,
            method: 'POST',
            data: { form_action: 'remove', media_id: itemId }
          })

          if (response.message) {
            item.remove()
          }
        } catch (error) {
          notifyBugsnag(error);
        }
      }
    }
  }
}

const initMedia = (media) => {
  media.addEventListener('dragstart', onDragStart)
  media.addEventListener('dragend', onDragEnd)
  media.addEventListener('dragover', onDragOver)
  media.addEventListener('dragleave', onDragLeave)
  media.addEventListener('drop', (event) => onDrop(event, media))
  media.addEventListener('keydown', onKeyDown)

  media.querySelectorAll(DELETE_BUTTON_CLASS).forEach(button => {
    button.addEventListener('click', handleDelete)
  })
}

document.querySelectorAll(MEDIA_CLASS).forEach(initMedia)
